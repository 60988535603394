import React, { FC, memo, useMemo } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import BoxComponent from '../../custom/BoxComponent';
import CustomCheckbox from '../../custom/CustomCheckbox';
import { ShorelineOutput } from '../../../models/inputTypes/ShorelineFields';

const styles = {
  spacingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  alignEndContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  childSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 1,
    },
  },
} as const;

type InputsProps = {
  inputState: ShorelineOutput;
  setInputState: (value: ShorelineOutput) => void;
};
const ShorelineOutputGroup: FC<InputsProps> = ({ inputState, setInputState }) => {
  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onCheckboxClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const currentValue: 'geojson' | 'csv' = e.target.id;
    const newValue = inputState[currentValue] ? 0 : 1;
    setInputState({ ...inputState, [currentValue]: newValue });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Box>
        <LabelWrapper label={'Enter Output filename'}>
          <CustomInput
            type={'text'}
            required
            maxWidth={'200px'}
            placeholder={'Enter name'}
            value={inputState.outputFileName}
            name={'outputFileName'}
            onChange={onInputChange}
          />
        </LabelWrapper>
      </Box>
      <Box mt={2}>
        <LabelWrapper label={'File Type'}>
          <Box sx={{ ...styles.alignEndContainer }}>
            <Box sx={{ ...styles.spacingContainer, ...styles.childSpacing }}>
              <CustomCheckbox checked={!!inputState.csv} />
              <Typography variant="body2" sx={{ fontSize: '12px' }}>
                .csv
              </Typography>
            </Box>
            <Box sx={{ ...styles.alignEndContainer }}>
              <Box mr={2} sx={{ ...styles.spacingContainer, ...styles.childSpacing }}>
                <CustomCheckbox checked={!!inputState.geojson} id={'geojson'} onClick={onCheckboxClick} />
                <Typography variant="body2" sx={{ fontSize: '12px' }}>
                  .geojson
                </Typography>
              </Box>
              <BoxComponent disabled={!inputState.geojson}>
                <LabelWrapper label={'CRS EPSG code'}>
                  <CustomInput
                    required
                    placeholder={'-'}
                    min={1024}
                    max={32767}
                    step={1}
                    maxWidth={'85px'}
                    disabled={!inputState.geojson}
                    errorText={'Enter number 1024-32767'}
                    type={'number'}
                    value={inputState.inpEPSG}
                    name={'inpEPSG'}
                    onChange={onNumberChange}
                  />
                </LabelWrapper>
              </BoxComponent>
            </Box>
          </Box>
        </LabelWrapper>
      </Box>
    </>
  );
};

export default memo(ShorelineOutputGroup);
