import React from 'react';
import { styled, Switch, SwitchProps } from '@mui/material';

const CustomSwitch = styled((props: SwitchProps) => <Switch disableRipple {...props} />)(({ theme }) => ({
  width: 46,
  height: 20,
  padding: 0,
  overflow: 'visible',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: -1,
    '&.Mui-checked': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
      transform: 'translateX(24px)',
      color: theme.palette.info.contrastText,
      '&.Mui-disabled': {
        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
        boxShadow: 'none',
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#F5F5F5',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.info.light,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.24)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#d6d6d6',
    opacity: 1,
    boxShadow: '0px 0px 0 1px #CCC',
  },
}));

export default CustomSwitch;
