import {
  NourishmentsObject,
  ShorefaceNourishmentsObject,
  PlotObject,
  WaveGenObject,
  ShorelinePlotFrame,
} from '../../models/inputTypes/ShorelineFields';
import { InputFile } from '../../models/jobModels/ModelsFileTypes';

export const prepareNourishments = (list: NourishmentsObject[]) => {
  const nourishments: NourishmentsObject[] = [];

  list.forEach((item) => {
    nourishments.push({
      swX: item.swX,
      swY: item.swY,
      neX: item.neX,
      neY: item.neY,
      rate: item.rate,
      tstart: item.tstart,
      tend: item.tend,
    });
  });
  return [...nourishments];
};

export const prepareShorefaceNourishments = (list: ShorefaceNourishmentsObject[]) => {
  const nourishments: ShorefaceNourishmentsObject[] = [];

  list.forEach((item) => {
    nourishments.push({
      swX: item.swX,
      swY: item.swY,
      neX: item.neX,
      neY: item.neY,
      rate: item.rate,
      nour_len: item.nour_len,
      d: item.d,
      d50: item.d50,
      tstart: item.tstart,
      k: item.k,
    });
  });
  return [...nourishments];
};

export const prepareRefCoast = (inputFiles: Partial<InputFile>[], labels: string[], colors: string[]) => {
  return inputFiles.map((file, i) => {
    const currentFile = {
      currentFile: { project: +(file.project ?? ''), name: file.fileid },
      fileName: file.fileid ?? '',
    };
    return { file: currentFile, label: labels[i], color: colors[i] };
  });
};

export const prepareWaveGen = (
  inputFiles: Partial<InputFile>[],
  X: (number | undefined)[],
  Y: (number | undefined)[],
) => {
  return inputFiles.map((file, i) => {
    const currentFile = {
      currentFile: { project: +(file.project ?? ''), name: file.fileid },
      fileName: file.fileid ?? '',
    };
    return { file: currentFile, X: X[i], Y: Y[i] };
  });
};

export const prepareLabels = (list: PlotObject[]) => {
  const labels: string[] = [];

  list.forEach((item, i) => {
    labels.push(item.label);
  });
  return [...labels];
};

export const prepareColors = (list: PlotObject[]) => {
  const colors: string[] = [];
  list.forEach((item, i) => {
    colors.push(item.color);
  });
  return [...colors];
};

export const prepareX = (list: WaveGenObject[]) => {
  const X: (number | undefined)[] = [];
  list.forEach((item, i) => {
    X.push(item?.X);
  });
  return [...X];
};

export const prepareY = (list: WaveGenObject[]) => {
  const Y: (number | undefined)[] = [];
  list.forEach((item, i) => {
    Y.push(item.Y);
  });
  return [...Y];
};

export const prepareFrameValues = (list: ShorelinePlotFrame[], projectId: string) => {
  const frameValues: any[] = [];

  list.forEach((item) => {
    const logoFile = item.logoFile?.currentFile?.name
      ? { logo_file: { fileid: item.logoFile.currentFile.name, project: projectId } }
      : undefined;
    frameValues.push({
      frame: item.frame ? +item.frame : undefined,
      label: item.label ? 1 : 0,
      userID: item.userIdType === 'username' ? 1 : 2,
      ...(item.userIdType === 'username' ? { userName: item.username.toString() } : undefined),
      ...logoFile,
      clientName: item.client.toString(),
      projectTitle: item.projectTitle.toString(),
      figureDescription: item.figureDescription.toString(),
      figureNumber: item.figureNumber.toString(),
      figureTitle: item.figureTitle.toString(),
      date: item.date.toString(),
    });
  });
  return [...frameValues];
};

export const prepareSimFrameValues = (list: any[]) => {
  const frameValues: ShorelinePlotFrame[] = [];
  list.forEach((item) => {
    const logoFile = item?.logo_file;
    frameValues.push({
      frame: item.frame,
      label: item.label == 1,
      userIdType: item.userID == 2 ? 'logo' : 'username',
      username: item.userName,
      logoFile: logoFile
        ? { currentFile: { project: +logoFile.project, name: logoFile.fileid }, fileName: logoFile.fileid }
        : undefined,
      client: item.clientName.toString(),
      projectTitle: item.projectTitle,
      figureDescription: item.figureDescription,
      figureNumber: item.figureNumber,
      figureTitle: item.figureTitle,
      date: item.date,
    });
  });
  return [...frameValues];
};
