import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Collapse, Typography } from '@mui/material';
import CustomMenuItem from '../../custom/CustomMenuItem';
import CustomSelect from '../../custom/CustomSelect';
import LabelWrapper from '../../common/LabelWrapper';
import CustomInput from '../../custom/CustomInput';
import CustomRadioButton from '../../custom/CustomRadioButton';
import CustomTabs from '../../custom/CustomTabs';
import BoxComponent from '../../custom/BoxComponent';
import { ShorelinePlotLabelPdf, ShorelinePlotFrame } from '../../../models/inputTypes/ShorelineFields';
import ButtonFileDialog from '../../common/ButtonFileDialog';
import { FileType } from '../../../models/inputTypes/FileType';
import { shorelinePlotFramesInitialState } from '../../../utils/initialStates/shorelineInputState';
import CustomTextArea from '../../custom/CustomTextArea';
import CustomSwitch from '../../custom/CustomSwitch';
import { plotTabPreparation } from '../../../utils/functions/PlotTabPreparation';

const styles = {
  itemSpacing: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  wrap: {
    flexWrap: 'wrap',
  },
  line: {
    bgcolor: 'info.light',
    height: 2,
  },
  flexStartBox: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  childMargin: {
    '& >:nth-child(n)': {
      my: 1,
      mr: 4,
    },
  },
  flexStartContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
} as const;

type InputsProps = {
  projectId: string;
  reftime: string;
  simend: string;
  fignryear: number;
  inputState: ShorelinePlotLabelPdf;
  setInputState: (value: ShorelinePlotLabelPdf) => void;
};

const ShorelinePlotLabelInputsGroup: FC<InputsProps> = ({
  projectId,
  reftime,
  simend,
  fignryear,
  inputState,
  setInputState,
}) => {
  const [selectedPlot, setSelectedPlot] = useState(0);
  const [frame, setFrame] = useState(0);

  const onInputChange = (
    event: SelectChangeEvent<unknown> | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const onRadioClick = (value: any) => {
    const newPlotArray = inputState.plotFrameValues.slice();
    newPlotArray[selectedPlot] = {
      ...newPlotArray[selectedPlot],
      userIdType: value,
      username: shorelinePlotFramesInitialState.username,
      logoFile: undefined,
    };
    setInputState({ ...inputState, plotFrameValues: newPlotArray });
  };

  const onFileChange = (file: FileType | undefined, fieldName: string) => {
    const newPlotArray = inputState.plotFrameValues.slice();
    newPlotArray[selectedPlot] = {
      ...newPlotArray[selectedPlot],
      [fieldName]: file,
    };
    setInputState({ ...inputState, plotFrameValues: newPlotArray });
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputState({ ...inputState, [event.target.name]: event.target.value === '' ? undefined : +event.target.value });
  };

  const onFrameArrayChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newPlotArray = inputState.plotFrameValues.slice();
    newPlotArray[selectedPlot] = {
      ...newPlotArray[selectedPlot],
      [event.target.name]: event.target.value,
    };
    setInputState({ ...inputState, plotFrameValues: newPlotArray });
  };

  const switchChange = (e: any) => {
    setInputState({ ...inputState, [e.target.name]: e.target.checked });
  };

  const switchChangeArray = (e: any) => {
    const newPlotArray = inputState.plotFrameValues.slice();
    newPlotArray[selectedPlot] = { ...newPlotArray[selectedPlot], [e.target.name]: e.target.checked };
    setInputState({ ...inputState, plotFrameValues: newPlotArray });
  };

  useEffect(() => {
    const startDate = new Date(reftime);
    const endDate = new Date(simend);
    const durationYears = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
    const figures = Math.floor(durationYears * fignryear) + 1;
    const tempArray: ShorelinePlotFrame[] = [];
    inputState.plotFrameValues.map((item, i) => {
      tempArray.push({ ...item, frame: figures });
    });
    setInputState({ ...inputState, plotFrameValues: tempArray });
  }, []);

  const prepareSelectItems = () => {
    return [1, 2, 3, 4, 5].map((item, i) => (
      <CustomMenuItem key={i} value={item}>
        <Typography variant={'subtitle2'}>{item}</Typography>
      </CustomMenuItem>
    ));
  };

  const onSelectFrameChange = (e: any) => {
    const newValue = parseInt(e.target.value);
    const startDate = new Date(reftime);
    const endDate = new Date(simend);
    const durationYears = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25);
    const figures = Math.floor(durationYears * fignryear) + 1;
    const tempArray: ShorelinePlotFrame[] = [];
    for (let i = 0; i < newValue; i++) {
      if (inputState.plotFrameValues[i]) {
        tempArray.push(inputState.plotFrameValues[i]);
      } else {
        tempArray.push({ ...shorelinePlotFramesInitialState, frame: figures });
      }
    }
    setInputState({ ...inputState, plotFrameValues: tempArray, plotFrames: newValue });
  };

  const onLineChange = (event: any, newValue: number) => {
    setSelectedPlot(newValue);
  };

  const plotFrameTabs = useMemo(() => {
    return plotTabPreparation(inputState.plotFrames, 'frame');
  }, [inputState.plotFrames]);

  return (
    <Box>
      <Typography my={2} variant={'subtitle1'}>
        Select orientation
      </Typography>
      <LabelWrapper label={'A4 Page Orientation'}>
        <CustomSelect
          required
          value={inputState.pageOrientation}
          name={'pageOrientation'}
          onChange={onInputChange}
          minWidth={'140px'}
          defaultValue={''}
          displayEmpty
        >
          <CustomMenuItem value={'1'}>
            <Typography variant={'subtitle2'}>Portrait</Typography>
          </CustomMenuItem>
          <CustomMenuItem value={'2'}>
            <Typography variant={'subtitle2'}>Landscape</Typography>
          </CustomMenuItem>
        </CustomSelect>
      </LabelWrapper>
      <Typography my={2} variant={'subtitle1'}>
        Basemap
      </Typography>
      <Box sx={{ ...styles.childMargin, ...styles.flexStartContainer }}>
        <Box>
          <LabelWrapper label={'Plot Basemap'}>
            <Grid container justifyContent={'center'} width={'100px'}>
              <CustomSwitch checked={inputState.plotBasemap} name={'plotBasemap'} onChange={switchChange} />
            </Grid>
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'CRS EPSG code'}>
            <CustomInput
              required
              placeholder={'-'}
              min={1024}
              max={32767}
              step={1}
              maxWidth={'85px'}
              disabled={!inputState.plotBasemap}
              errorText={'Enter number 1024-32767'}
              type={'number'}
              value={inputState.inpEPSG}
              name={'inpEPSG'}
              onChange={onNumberChange}
            />
          </LabelWrapper>
        </Box>
        <Box>
          <LabelWrapper label={'Map Layer'}>
            <CustomSelect
              required
              placeholder={'-'}
              disabled={!inputState.plotBasemap}
              value={inputState.mapProvider}
              name={'mapProvider'}
              onChange={onInputChange}
              minWidth={'140px'}
              defaultValue={''}
              displayEmpty
            >
              <CustomMenuItem key={'map-1'} value={1}>
                <Typography variant={'subtitle2'}>World Satellite (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-2'} value={2}>
                <Typography variant={'subtitle2'}>World Street Map (ESRI)</Typography>
              </CustomMenuItem>
              <CustomMenuItem key={'map-3'} value={3}>
                <Typography variant={'subtitle2'}>World Topo Map (ESRI)</Typography>
              </CustomMenuItem>
            </CustomSelect>
          </LabelWrapper>
        </Box>
      </Box>
      <Typography mb={1} variant={'subtitle2'}>
        Select plot frames
      </Typography>
      <CustomSelect
        required
        value={inputState.plotFrames}
        onChange={onSelectFrameChange}
        name={'plotFrames'}
        minWidth={'140px'}
        defaultValue={''}
        displayEmpty
      >
        {prepareSelectItems()}
      </CustomSelect>
      <Box mt={2}>
        <Collapse in={true} collapsedSize={1}>
          <CustomTabs
            variant={'scrollable'}
            scrollButtons={'auto'}
            value={selectedPlot}
            name={'selectedPlot'}
            onChange={onLineChange}
          >
            {plotFrameTabs}
          </CustomTabs>
          {inputState.plotFrameValues[selectedPlot] ? (
            <>
              <Typography my={2} variant={'subtitle1'}>
                Select snapshot
              </Typography>
              <Box>
                <LabelWrapper label={'Frame'}>
                  <CustomInput
                    required
                    maxWidth={'85px'}
                    errorText={'Enter positive number'}
                    // type={'number'}
                    value={inputState.plotFrameValues[selectedPlot].frame}
                    name={'frame'}
                    onChange={onFrameArrayChange}
                  />
                </LabelWrapper>
              </Box>
              <Typography my={2} variant={'subtitle1'}>
                Label
              </Typography>
              <Box>
                <LabelWrapper label={'Include label'}>
                  <Grid container justifyContent={'center'} width={'100px'}>
                    <CustomSwitch
                      checked={inputState.plotFrameValues[selectedPlot].label}
                      name={'label'}
                      onChange={switchChangeArray}
                    />
                  </Grid>
                </LabelWrapper>
              </Box>
              {inputState.plotFrameValues[selectedPlot].label && (
                <>
                  <Typography my={2} variant={'subtitle1'}>
                    User #ID
                  </Typography>
                  <Box sx={{ ...styles.flexStartBox }}>
                    <Box mr={10}>
                      <CustomRadioButton
                        checked={inputState.plotFrameValues[selectedPlot].userIdType === 'username'}
                        onButtonClick={onRadioClick}
                        value={'username'}
                        textLabel={'Username'}
                      />
                    </Box>
                    <BoxComponent disabled={inputState.plotFrameValues[selectedPlot].userIdType != 'username'}>
                      <LabelWrapper label={'Enter username'}>
                        <CustomInput
                          errorText={'Please enter text'}
                          required
                          disabled={inputState.plotFrameValues[selectedPlot].userIdType != 'username'}
                          value={inputState.plotFrameValues[selectedPlot].username}
                          name={'username'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </BoxComponent>
                  </Box>
                  <Box mt={2} sx={{ ...styles.flexStartBox }}>
                    <Box mr={14}>
                      <CustomRadioButton
                        checked={inputState.plotFrameValues[selectedPlot].userIdType === 'logo'}
                        onButtonClick={onRadioClick}
                        value={'logo'}
                        textLabel={'Logo'}
                      />
                    </Box>
                    <BoxComponent disabled={inputState.plotFrameValues[selectedPlot].userIdType !== 'logo'}>
                      <LabelWrapper label={'Insert logo file'}>
                        <ButtonFileDialog
                          typePage={'PLOT'}
                          imageFileOnly
                          disabled={!projectId || inputState.plotFrameValues[selectedPlot].userIdType !== 'logo'}
                          projectId={projectId}
                          required
                          file={inputState.plotFrameValues[selectedPlot].logoFile}
                          setFile={(file) => onFileChange(file, 'logoFile')}
                        />
                      </LabelWrapper>
                    </BoxComponent>
                  </Box>

                  <Box mx={5} my={2} sx={styles.line} />

                  <Box sx={{ ...styles.flexStartBox, ...styles.itemSpacing, ...styles.wrap }}>
                    <Box>
                      <LabelWrapper label={'Figure number'}>
                        <CustomInput
                          required
                          placeholder={'-'}
                          errorText={'Please enter text'}
                          value={inputState.plotFrameValues[selectedPlot].figureNumber}
                          name={'figureNumber'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                    <Box>
                      <LabelWrapper label={'Figure title'}>
                        <CustomInput
                          required
                          errorText={'Please enter text'}
                          placeholder={'-'}
                          value={inputState.plotFrameValues[selectedPlot].figureTitle}
                          name={'figureTitle'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                    <Box>
                      <LabelWrapper label={'Date'}>
                        <CustomInput
                          errorText={'Please enter text'}
                          required
                          placeholder={'-'}
                          value={inputState.plotFrameValues[selectedPlot].date}
                          name={'date'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                    <Box>
                      <LabelWrapper label={'Client'}>
                        <CustomInput
                          minWidth={'380px'}
                          required
                          errorText={'Please enter text'}
                          placeholder={'-'}
                          value={inputState.plotFrameValues[selectedPlot].client}
                          name={'client'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                    <Box>
                      <LabelWrapper label={'Project title'}>
                        <CustomInput
                          required
                          minWidth={'380px'}
                          errorText={'Please enter text'}
                          placeholder={'-'}
                          value={inputState.plotFrameValues[selectedPlot].projectTitle}
                          name={'projectTitle'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                    <Box>
                      <LabelWrapper label={'Figure description'}>
                        <CustomTextArea
                          rows={7}
                          minWidth={'380px'}
                          required
                          errorText={'Please enter text'}
                          placeholder={'-'}
                          value={inputState.plotFrameValues[selectedPlot].figureDescription}
                          name={'figureDescription'}
                          onChange={onFrameArrayChange}
                        />
                      </LabelWrapper>
                    </Box>
                  </Box>
                </>
              )}
            </>
          ) : undefined}
        </Collapse>
      </Box>
    </Box>
  );
};

export default memo(ShorelinePlotLabelInputsGroup);
